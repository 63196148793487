// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter, useRoutes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import About from './pages/About';
import NoPage from './pages/NoPage';
import './App.css';

function Routes({ isAuthenticated }: { isAuthenticated: boolean }) {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/home', element: <Home /> },
    { path: '/dashboard', element: isAuthenticated ? <Dashboard /> : <Navigate to="/home" /> },
    { path: '/about', element: <About /> },
    { path: '*', element: <NoPage /> },
  ]);

  return routes;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const response = await fetch('https://alb.deardiary.io/isAuthenticated', { credentials: 'include' });
      const data = await response.json();
      setIsAuthenticated(data.authenticated);
    };

    fetchAuthStatus();
  }, []);

  return (
    <div>
      <BrowserRouter>
        {isAuthenticated !== null && <Routes isAuthenticated={isAuthenticated} />}
      </BrowserRouter>
    </div>
  );
}

export default App;