import React from 'react';
import Header from "../components/HeaderComponent/Header";
import { isAbsolute } from 'path';

export default function Home() {
  const handleLogin = () => {
    window.location.href = 'https://alb.deardiary.io/oauth2/authorization/auth0';
  };

  const handleAbout = () => {
    window.location.href = '/about';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      {/* <Header /> */}
      <h1>Home Page</h1>
      <button 
        style={{ backgroundColor: '#D8BFD8', color: 'black', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer', fontSize: '16px' }} 
        onClick={handleLogin}
      >
        Login
      </button>
      
      <br>
      </br>

      <button
      style={{ backgroundColor: '#D8BFD8', color: 'black', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer', fontSize: '16px' }}
      onClick={handleAbout}
      // onClick={handleLogin}
      >
        About
      </button>
      <p>Next release: January 15th, Wednesday, 2025.</p>
      <p>up until all the releases, in the update thread below, i'll add updates on the 15th and 23rd of every month to keep updates on how i'm building this and the progress, also what i'm learning throughout it and just funny shit</p>
      <p><a href='https://www.linkedin.com/posts/deardiary-io_project-update-thread-to-view-new-and-recent-activity-7218912027489005570-9AMp?utm_source=share&utm_medium=member_desktop'>https://www.linkedin.com/posts/deardiary-io_project-update-thread</a></p>
      <p>🥛🥺</p>

      <div style={{position: 'absolute', bottom: 0, right: 0, width: '100', textAlign: 'right', fontSize: '20px', padding: '10px'}}>J</div>
    </div>
  );
}
