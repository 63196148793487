import React, { useState, useEffect } from 'react';
import Header from "../components/HeaderComponent/Header";

interface UserDetails {
    name: string;
    email: string;
}

export default function Dashboard() {

    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

    useEffect(() => {
      const fetchUserDetails = async () => {
        try {
            const response = await fetch('https://alb.deardiary.io/userDetails', { credentials: 'include' });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setUserDetails(data);
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

        fetchUserDetails();
    }, []);

    const handleLogout = () => {
        window.location.href = 'https://alb.deardiary.io/logout';
  };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          {/* <Header /> */}
          <h1>Dashboard Page</h1>
          {userDetails && (
              <>
                  <h2>Welcome, {userDetails.name}</h2>
                  {/* <p>Email: {userDetails.email}</p> */}
              </>
          )}
          <br></br>
          <button 
              style={{ backgroundColor: '#D8BFD8', color: 'black', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer', fontSize: '16px' }} 
              onClick={handleLogout}
          >
              Logout
          </button>
      </div>
  );
}