import React from 'react';
import Header from "../components/HeaderComponent/Header";

export default function About() {

  const handleHome = () => {
    window.location.href = '/home';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {/* <Header /> */}
      <h2>About Page</h2>
      <p>example diary entries, this content is passed through CloudFront CDN with source being S3, i need to work on this page breh. todo: update on September 15th
      </p>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
        {/* Video container */}

        <p>Monday December 30th, 1:19am, 2017</p>
        <video controls loop style={{ width: "80%", maxWidth: "500px", maxHeight: "250px", height: "auto", margin: "10px 0" }} poster="https://d5tn2g2axo0fb.cloudfront.net/thumbnail.png" >
          <source
            src="https://d5tn2g2axo0fb.cloudfront.net/2017December30th.mp4"
            type="video/mp4"
          />
        </video>
        <video controls loop style={{ width: "80%", maxWidth: "500px", maxHeight: "250px", height: "auto", margin: "10px 0" }} poster="https://d5tn2g2axo0fb.cloudfront.net/sweethome.png">
          <source
            src="https://d5tn2g2axo0fb.cloudfront.net/home.mp4"
            type="video/mp4"
          />
        </video>
        <p>Tuesday March 2nd, 6:48pm, 2024</p>
        <audio controls loop style={{ width: "80%", margin: "10px 0" }}>
          <source
            src="https://d5tn2g2axo0fb.cloudfront.net/winsAudio.m4a"
            type="audio/mp4"
          />
          Your browser does not support the audio element.
        </audio>
        <p>Tuesday March 2nd, 6:56pm, 2024</p>
        <audio controls loop style={{ width: "80%", margin: "10px 0" }}>
          <source
            src="https://d5tn2g2axo0fb.cloudfront.net/cornflakesaudio.m4a"
            type="audio/mp4"
          />
          Your browser does not support the audio element.
        </audio>
        <p>todo: attach written diary examples on 15th September here</p>
      </div>
      <button 
        style={{ backgroundColor: '#D8BFD8', color: 'black', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer', fontSize: '16px', marginTop: '20px', marginBottom: '40px' }} 
        onClick={handleHome}
      >
        Home
      </button>

      <div style={{position: 'absolute', bottom: 0, right: 0, width: '100', textAlign: 'right', fontSize: '20px', padding: '10px'}}>J</div>
    </div>
  );
}
